@import '3_styles/variables';
@import '3_styles/_mixins';

:export {
  opened-color: $dark-default-background;
  selected-color: $dark-active-font;
}

.CentiloidBar {
  // border:1px red solid;
  width: 60%;
  height: 3px;
  background: $dark-layer2-background;
  overflow: hidden;
  border-radius: 5px;
  > div {
    // border:1px red solid;
    background: red;
    height: 100%;
    border-radius: 5px;
  }
}
.TracerMarker {
  border-left: 0.3vw solid;
  box-sizing: border-box;
}

.selectPageNum {
  // border:1px solid yellowgreen;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  .selectNum_Arr {
    display: flex;
    width: 50%;
    // border: 1px solid cyan;
    align-items: center;
    justify-content: center;
    // padding: 0 1vw;
    // gap: 3vw;
    > div:nth-child(1) {
      // border:1px solid red;
      display: flex;
      align-items: center;
    }
    > div {
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1vw;
      height: 100%;
      cursor: pointer;
    }
    > div:last-child(1) {
      // border:1px solid red;
      display: flex;
      align-items: center;
    }
  }
}
.arrowIcon {
  // border:1px solid red;
  > img {
    width: 0.8vw;
  }
}

.table {
  user-select: none;
  cursor: auto;
  border-collapse: collapse;
  // border: 4px yellow solid;
  width: 100%;
  text-align: center;
  table-layout: fixed;
  th,
  td {
    // border:1px red solid;
    white-space: nowrap;
    // overflow:scroll;
    &.Select {
      width: 6.5%;
      // min-width:1000px;
    }
    &.Tracer {
      width: 8%;
    }
    &.Centiloid {
      width: 10%;
      // border:1px solid red;
      padding-right: 0.7vw;
      // display: flex;
      // width: 100%;
    }
    &.SBR {
      // border:1px solid red;
      width: 8%;
    }
    &.Patient_name {
      width: 30%;
      // border:1px red solid;
      // border:none;
      padding: 0 10px;
      overflow-x: auto;

      @include scrollInvisible();
    }
    &.Patient_ID {
      width: 15%;
      // border: 4px yellow solid;
    }
    &.DOB {
      width: 10%;
      // border: 4px yellow solid;
    }
    &.Modality {
      width: 7%;
    }
    &.Study_Description {
      width: 30%;
    }
    &.Sex {
      width: 5%;
    }
    &.Scan_date {
      width: 15%;
    }
    &.Uploaded_date {
      width: 15%;
    }
  }
  > thead {
    position: sticky;
    top: 0;
    // text-align: center;
    background-color: #313438;
    height: 2.5vw;
    > tr {
      > th {
        font-weight: 400;
        font-size: calc(6px + 0.5vw);
        // border:1px solid yellow;
        > div {
          // border:1px solid red;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          > span {
            height: 100%;
          }
          > div {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 0.2vw;
            > img {
              height: 0.8vw;
              width: 0.8vw;
            }
          }
        }
      }
    }
  }
  > tbody {
    background-color: $dark-layer1-background;
    > .trMain {
      height: 2.5vw;
      // border-top: 1px solid #797a7c;
      &:hover {
        background-color: $dark-hover;
      }
      > td {
        > img {
          // border: 1px solid red;
          height: 0.75vw;
          width: 0.75vw;
        }
        font-weight: $header-uploadtable-fontweight;
        font-size: calc(6px + 0.5vw);
      }
    }
  }
}

.centiolodInfoWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  // border:1px solid red;
  height: 100%;
  > img {
    width: 1.2vw;
  }
}

.centiloidMouseOverInfo {
  // border:1px solid red;
  background-color: $dark-layer2-background;
  position: absolute;
  top: 75%;
  left: 18%;
  width: 9vw;
  height: 2.5vw;
  white-space: normal;
  font-size: 0.5vw;
  display: flex;
  align-items: center;
}

.processingIcon {
  // border:1px solid red;
  width: 1.5vw;
  height: 1vw;
}

.slideContainer {
  white-space: nowrap;
  animation-name: marquee;
  animation-timing-function: linear;
  animation-duration: 10s;
  animation-iteration-count: infinite;
}

@keyframes marquee {
  from {
    transform: translate(0%);
  }
  99%,
  to {
    transform: translate(-100%);
  }
}
