@import '3_styles/_variables';
@import '3_styles/_mixins';

.table {
  table-layout: fixed;
  border-spacing: 5px;
  width: 100%;
  margin-top: 20px;

  td {
    height: 25px;
    text-align: left;
    vertical-align: top;

    &:nth-child(1) {
      font-size: 17px;
      font-weight: 600;
      display: flex;
    }

    &:nth-child(2) {
      width: 75%;
      font-size: 16px;
      font-weight: 400;
      word-break: break-all;
    }
  }
}
