@import '3_styles/_variables';
@import '3_styles/_mixins';
.container {
  // @include borderBox(1px, blue);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  // padding-top: 2vw;
  // padding-left: 0.5vw;
  @include flexTo(column, flex-start, stretch);
  .top {
    background: $dark-default-background;
    // @include borderBox(5px, yellow);
    // padding: 1vw 2vw;
    font-size: calc(6px + 0.5vw);
    box-sizing: border-box;
  }
  .bottom {
    // @include borderBox(1px, blue);
    @include flexTo(row, flex-start, flex-start);
    flex-flow: row wrap;
    overflow: auto;
    height: 100%;
    background: $dark-default-background;
  }
}
