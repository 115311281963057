@import '3_styles/variables';

.sub-container {
  // box-sizing: border-box;
  // border: 1px solid white;
  display: flex;
  align-items: center;
  // justify-content: center;
  height: 3vw;
  padding: 0 0 0 0.5vw;
  cursor: pointer;
  color: white;
  opacity: 0.3;

  &.enabled {
    opacity: 1;

    &:hover {
      border-right: 0.3vw solid $dark-active-font;
      background-color: $dark-default-background;
    }
  }

  &.active {
    background-color: $dark-default-background;
    border-right: 0.3vw solid $dark-active-font;
    color: $dark-active-font;
  }
}

.menu-icon {
  // border: 1px solid red;
  box-sizing: border-box;
  display: flex;
  align-items: center;

  > img {
    // border: 1px solid red;
    box-sizing: border-box;
    margin: 0 0.5vw;
    width: 2vw;
    height: 2vw;
  }
}

.menu-name {
  // border: 1px solid yellow;
  box-sizing: border-box;
  font-size: $font-size-menu;
  display: flex;
  align-items: center;
  height: 2vw;
  // margin-left: 0.3vw;
  font-family: 'Montserrat';
  font-weight: normal;
  // color: white;
  // width: 100%;
}
