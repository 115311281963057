@import '3_styles/_variables';
@import '3_styles/_mixins';

.container {
  @include flexTo(row, center, center);
  // @include borderBox(1px, red);
  background: rgba(0, 0, 0, 0.5);
  color: white;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 2;
  > .boxContainer {
    // border: 1px red solid;
    width: 60%;
    height: 75%;
    padding: 2% 4% 2% 4%;
    box-sizing: border-box;
    background: $dark-layer2-background;
    @include flexTo(column, flex-start, flex-start);
    > .boxTitle {
      // border: 1px red solid;
      font-weight: 400;
      font-size: 1.2vw;
    }
    > .boxContent {
      // border: 1px red solid;
      background: $dark-layer1-background;
      height: 100%;
      width: 100%;
      overflow-y: scroll;
    }
    > .saveButton {
      // border:5px red solid;
      margin-top: 20px;
      margin-left: auto;
      width: 5vw;
      height: 2vw;
      // font-size: 1vw;
      background: $dark-active-font;
      color: white;
      font-weight: 600;
      @include flexTo(row, center, center);
      user-select: none;
      cursor: pointer;
      &:hover {
        @include borderBox(1px, white);
      }
    }
  }
  > .exitButton {
    cursor: pointer;
    user-select: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 2vw;
    height: 2vw;
    border: 1px red solid;
    font-size: 1.5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      color: $dark-active-font;
    }
  }
}

.brain_regions_analyze_subContent {
  // border: 5px solid yellow;
  box-sizing: border-box;
  width: 100%;
  padding: 2vw 0 0 2vw;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  > div {
    // border: 5px solid cyan;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin-bottom: 2vw;
    gap: 1vw;
    > div:nth-child(2) {
      margin-left: 1vw;
      // border: 1px solid red;
      flex-wrap: wrap;
      display: flex;
      gap: 0.5vw;
    }
  }
}

.brain_regions_analyze_subContent_subregion_overflow {
  // border: 1px solid red;
  cursor: default;
  width: 9.5vw;
  height: 2vw;
  background-color: $dark-default-background;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 0.5vw;
  font-style: normal;
  font-weight: 300;
  font-size: 0.8vw;
  border-radius: 3px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  > div:nth-child(1) {
    // border: 1px solid yellow;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    white-space: nowrap;
    &:hover {
      white-space: nowrap;
      -webkit-animation-name: marquee;
      -webkit-animation-timing-function: linear;
      -webkit-animation-duration: 3s;
      -webkit-animation-iteration-count: infinite;
      -moz-animation-name: marquee;
      -moz-animation-timing-function: linear;
      -moz-animation-duration: 3s;
      -moz-animation-iteration-count: infinite;
      -ms-animation-name: marquee;
      -ms-animation-timing-function: linear;
      -ms-animation-duration: 3s;
      -ms-animation-iteration-count: infinite;
      -o-animation-name: marquee;
      -o-animation-timing-function: linear;
      -o-animation-duration: 3s;
      -o-animation-iteration-count: infinite;
      animation-name: marquee;
      animation-timing-function: linear;
      animation-duration: 3s;
      animation-iteration-count: infinite;
    }
  }
  > div:nth-child(2) {
    // border: 1px solid cyan;
    position: absolute;
    right: 2%;
    height: 100%;
    width: 1vw;
    outline: 5px solid $dark-default-background;
    display: flex;
    align-items: center;
    background-color: $dark-default-background;
    > img {
      width: 1vw;
      height: 1vw;
    }
  }
}

.brain_regions_analyze_subContent_subregion {
  // border: 1px solid red;
  cursor: default;
  width: 9.5vw;
  height: 2vw;
  background-color: $dark-default-background;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 0.5vw;
  font-style: normal;
  font-weight: 300;
  font-size: 0.8vw;
  border-radius: 3px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  > div:nth-child(1) {
    // border: 1px solid yellow;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    white-space: nowrap;
  }
  > div:nth-child(2) {
    // border: 1px solid red;
    height: 100%;
    position: absolute;
    right: 2%;
    display: flex;
    align-items: center;
    background-color: $dark-default-background;
    width: 1vw;
    outline: 5px solid $dark-default-background;

    > img {
      width: 1vw;
      height: 1vw;
    }
  }
}

.brain_regions_analyze_subContent_title {
  display: flex;
  gap: 0.3vw;
  // border: 1px solid yellow;
  // > div:nth-child(1) {
  //   > img {
  //     width: 1vw;
  //     height: 1vw;
  //   }
  // }
  > div:nth-child(1) {
    font-weight: 500;
    font-size: 1vw;
    width: 100%;
    // border:1px solid red;
    > input {
      border: none;
      outline: none;
      background-color: #202223;
      color: white;
      // width: 7vw;
      height: 2vw;
      padding-left: 0.5vw;
      margin-left: 0.5vw;
    }
  }
}

@-webkit-keyframes marquee {
  from {
    -webkit-transform: translate(0%);
  }
  99%,
  to {
    -webkit-transform: translate(-100%);
  }
}
@-moz-keyframes marquee {
  from {
    -moz-transform: translate(0%);
  }
  99%,
  to {
    -moz-transform: translate(-100%);
  }
}
@-ms-keyframes marquee {
  from {
    -ms-transform: translate(0%);
  }
  99%,
  to {
    -ms-transform: translate(-100%);
  }
}
@-o-keyframes marquee {
  from {
    -o-transform: translate(0%);
  }
  99%,
  to {
    -o-transform: translate(-100%);
  }
}
@keyframes marquee {
  from {
    transform: translate(0%);
  }
  99%,
  to {
    transform: translate(-100%);
  }
}

.slideContainer {
  white-space: nowrap;
  animation-name: marquee;
  animation-timing-function: linear;
  animation-duration: 10s;
  animation-iteration-count: infinite;
}

@keyframes marquee {
  from {
    transform: translate(0%);
  }
  99%,
  to {
    transform: translate(-100%);
  }
}
