@mixin input_range_style2() {
  .input-range__slider {
    appearance: none;
    background: $dark-active-font;
    // border: 1px solid #3f51b5;
    border-radius: 100%;
    cursor: pointer;
    display: block;
    height: 1rem;
    margin-left: -0.5rem;
    margin-top: -0.65rem;
    outline: none;
    position: absolute;
    top: 50%;
    transition:
      transform 0.3s ease-out,
      box-shadow 0.3s ease-out;
    width: 1rem;
  }
  .input-range__slider:active {
    transform: scale(1.3);
  }
  .input-range__slider:focus {
    box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
  }
  .input-range--disabled .input-range__slider {
    background: #cccccc;
    border: 1px solid #cccccc;
    box-shadow: none;
    transform: none;
  }

  .input-range__slider-container {
    transition: left 0.3s ease-out;
  }

  .input-range__label {
    color: #aaaaaa;
    font-family: 'Helvetica Neue', san-serif;
    font-size: 0.8rem;
    transform: translateZ(0);
    white-space: nowrap;
  }

  .input-range__label--min,
  .input-range__label--max {
    bottom: -1.4rem;
    position: absolute;
  }

  .input-range__label--min {
    visibility: hidden;
    left: 0;
  }

  .input-range__label--max {
    visibility: hidden;
    right: 0;
  }

  .input-range__label--value {
    visibility: hidden;
    position: absolute;
    top: -1.8rem;
  }

  .input-range__label-container {
    left: -50%;
    position: relative;
  }
  .input-range__label--max .input-range__label-container {
    left: 50%;
  }

  .input-range__track {
    background: #eeeeee;
    border-radius: 0.3rem;
    cursor: pointer;
    display: block;
    height: 0.3rem;
    position: relative;
    transition:
      left 0.3s ease-out,
      width 0.3s ease-out;
  }
  .input-range--disabled .input-range__track {
    background: #eeeeee;
  }

  .input-range__track--background {
    left: 0;
    margin-top: -0.15rem;
    position: absolute;
    right: 0;
    top: 50%;
  }

  .input-range__track--active {
    background: $dark-active-font;
  }

  .input-range {
    height: 1rem;
    position: relative;
    width: 100%;
  }
}

@mixin input_range_style() {
  .input-range__slider {
    appearance: none;
    background: $dark-active-font;
    // border: 1px solid $dark-active-font;
    border-radius: 100%;
    cursor: pointer;
    display: block;
    height: 1rem;
    margin-left: -0.5rem;
    margin-top: -0.65rem;
    outline: none;
    position: absolute;
    top: 50%;
    transition:
      transform 0.3s ease-out,
      box-shadow 0.3s ease-out;
    width: 1rem;
  }
  .input-range__slider:active {
    transform: scale(1.3);
  }
  .input-range__slider:focus {
    box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
  }
  .input-range--disabled .input-range__slider {
    background: #cccccc;
    border: 1px solid #cccccc;
    box-shadow: none;
    transform: none;
  }

  .input-range__slider-container {
    transition: left 0.3s ease-out;
  }

  .input-range__label {
    color: #aaaaaa;
    font-family: 'Helvetica Neue', san-serif;
    font-size: 0.8rem;
    transform: translateZ(0);
    white-space: nowrap;
  }

  .input-range__label--min,
  .input-range__label--max {
    bottom: -1.4rem;
    position: absolute;
  }

  .input-range__label--min {
    visibility: hidden;
    left: 0;
  }

  .input-range__label--max {
    visibility: hidden;
    right: 0;
  }

  .input-range__label--value {
    visibility: hidden;
    position: absolute;
    top: -1.8rem;
  }

  .input-range__label-container {
    left: -50%;
    position: relative;
  }
  .input-range__label--max .input-range__label-container {
    left: 50%;
  }

  .input-range__track {
    background: #eeeeee;
    border-radius: 0.3rem;
    cursor: pointer;
    display: block;
    height: 0.3rem;
    position: relative;
    transition:
      left 0.3s ease-out,
      width 0.3s ease-out;
  }
  .input-range--disabled .input-range__track {
    background: #eeeeee;
  }

  .input-range__track--background {
    left: 0;
    margin-top: -0.15rem;
    position: absolute;
    right: 0;
    top: 50%;
  }

  .input-range__track--active {
    background: $dark-active-font;
  }

  .input-range {
    height: 1rem;
    position: relative;
    width: 100%;
  }
}

@mixin exitButton() {
  // border:1px solid red;
  cursor: pointer;
  user-select: none;
  position: absolute;
  right: 0%;
  top: 0%;
  width: 2vw;
  height: 2vw;
  // border:1px red solid;
  font-size: 1.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    color: $dark-active-font;
  }
}
@mixin flex-center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin cardContainer() {
  // @include borderBox(6px, yellow);
  // border:1px yellow solid;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@mixin flexTo($direction, $justify, $align) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin borderBox($width, $color) {
  border: $width $color solid;
  // border: $width transparent solid;
  box-sizing: border-box;
}

@mixin scrollInvisible() {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin scrollDefaultStyle() {
  &::-webkit-scrollbar {
    width: 10px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    // width:50%;
    background-color: grey;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: $dark-layer2-background;
  }
}

@mixin button-style-none {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

@mixin animation($delay, $duration, $iter, $fill, $name) {
  animation-delay: $delay;
  animation-duration: $duration;
  animation-iteration-count: $iter;
  animation-fill-mode: $fill;
  animation-name: $name;
}

@mixin keyframe($animation_name) {
  @keyframes #{$animation_name} {
    @content;
  }
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

%btn__common {
  height: 3vw;
  font-size: 1vw;
  margin-top: 1vw;

  > button {
    @include button-style-none();
    width: 100%;
    height: 100%;
    background-color: $dark-active-font;
  }
}
