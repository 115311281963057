@import '3_styles/_variables';
@import '3_styles/_mixins';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  // border: 5px yellow solid;
  position: relative;
  > .reportContainer {
    // @include borderBox(3px, red);
    position: absolute;
    transform: translate(-50%, 0);
    top: 0;
    left: calc(50% - 5vw); // layout 의 좌측 너비만큼 빼줌
    // z-index:-1;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 0;
  top: 4rem;
  right: 3rem;
  > div {
    display: flex;
    justify-content: center;
    gap: 0.5vw;
    width: 5.5vw;
    height: 0.5vw;
    // border: 1px yellow solid;
    background-color: #383c41;
    display: flex;
    align-items: center;
    margin: 0.2vw;
    padding: 0.8vw;
    // background: gray;
    user-select: none;
    cursor: pointer;
    font-size: 0.7vw;
    font-weight: 400;
    border-radius: 3px;
    // @include borderBox(1px, white);
    &:hover {
      // @include borderBox(1px, $dark-active-font);
      outline: 1px solid $dark-active-font;

      font-size: 0.7vw;
      font-weight: 400;
      border-radius: 3px;
      width: 5.5vw;
      height: 0.5vw;
    }
    &:active {
      background: $dark-layer1-background;
      font-size: 0.7vw;
      font-weight: 400;
      border-radius: 3px;
      width: 5.5vw;
      height: 0.5vw;
    }
  }
}
