@import '3_styles/_variables';
@import '3_styles/_mixins';

.container {
  padding: 0.3vw;
  height: 11vw;
  width: 18vw;
  background-color: #4d5055;
  z-index: 2;
  // position: absolute;
  position: fixed;
  transform: translate(-50%, 130%);
  // top:500%;
  left: 50%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 0.8vw gray;
}

.xIcon {
  width: 100%;
  // border: 1px solid yellow;
  text-align: end;
}

.contents_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.contents {
  height: 50%;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.7vw;

  input:focus::-webkit-input-placeholder,
  textarea:focus::-webkit-input-placeholder {
    color: transparent;
  }

  ::-webkit-input-placeholder {
    color: white;
    opacity: 1;
  }

  // border:1px solid red;
  > input {
    all: unset;
    padding-left: 0.5vw;
    margin-top: 0.2vw;
    outline: none;
    border: none;
    background-color: #202223;
    font-size: 1vw;
    display: block;
    width: 70%;
    color: white;
    height: 1.8vw;
    font-size: 0.8vw;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
  }
}

.title {
  // border:5px solid cyan;
  width: 100%;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5vw;

  > div {
    font-size: 0.8vw;
  }
}

.confirm_btn {
  // border:1px solid cyan;
  margin-top: 1vw;
  width: 5vw;
  height: 1.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #5e6166;
  border-radius: 5px;

  &:hover {
    background: #118af7;
  }
}
