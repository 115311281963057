@import '3_styles/_variables';
@import '3_styles/_mixins';

.container {
  @include flexTo(row, center, center);
  // @include borderBox(1px, red);
  background: rgba(0, 0, 0, 0.5);
  color: white;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 2;
}

.infoBox {
  // border:1px solid red;
  width: 33%;
  // height: 30%;
  background-color: #383c41;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  // padding: 1vw;
  position: relative;
}
.infoTitle {
  font-size: 1.3vw;
  font-weight: 600;
  margin-bottom: 1vw;
}
.contents {
  // border:1px solid red;
  display: flex;
  flex-direction: column;
}

.content {
  // border:1px solid blue;
  margin: 0.2vw;
  display: flex;
  justify-content: space-between;
  > div:nth-child(1) {
    font-size: 0.7vw;
    font-weight: 300;

    // width: 7vw;
  }
  > div:nth-child(2) {
    font-size: 0.7vw;
    font-weight: 300;
    // border:1px solid yellow;
    width: 60%;
  }
}
