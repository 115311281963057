@import '3_styles/_variables';
@import '3_styles/_mixins';

.table {
  width: 100%;
  height: 95%;
  text-align: center;
  border-collapse: collapse;
  table-layout: fixed;

  .tbody {
    tr {
      td {
        border-left: none;
        border-right: none;
        white-space: nowrap;

        &:first-child {
          width: 23%;
        }
        &:nth-child(2) {
          width: 77%;
        }
      }
    }

    tr:not(:last-child) {
      border-bottom: 1px solid #f2f2f2;

      td {
        border-bottom: 1px $dark-default-background solid;

        &:first-child {
          > div:nth-child(1) {
            @include flexTo(row, center, center);
            padding: 5px;
          }
        }
        &:nth-child(2) {
          width: 77%;

          > div {
            @include flexTo(row, flex-end, stretch);
            box-sizing: border-box;
            height: 50%;
            border-width: 0 12px 0 0;
            border-style: solid;
          }

          .valueText > div {
            width: 50px;
          }

          // color gradient bar
          .divForPadding {
            border: none;
            margin: 1vw 0 0.3vw 0;
            display: flex;
            align-items: center;

            .gradeintBar {
              width: 100%;
              height: 20px;
              border-radius: 10px;
            }
          }

          .labels {
            border: none;
            display: flex;
            justify-content: space-between;
            > span {
              font-size: 15px;
            }
          }
        }
      }
    }

    // color gradient bar
    tr:last-child {
      td {
        .divForPadding {
          border: none;
          margin: 25px 0 7px 0;
          display: flex;
          align-items: center;

          .gradeintBar {
            width: 100%;
            height: 18px;
            border-radius: 10px;
          }
        }

        .labels {
          border: none;
          display: flex;
          justify-content: space-between;
          > span {
            font-size: 15px;
          }
        }
      }
    }
  }
}
