@import '3_styles/variables';
@import '3_styles/_mixins';

.activeTab {
  background: $dark-default-background;
  color: $dark-active-font;
}
.container {
  // transition: all 0.3s ease;
  background: $dark-layer1-background;
  font-weight: $header-uploadtable-fontweight;
  position: fixed;
  top: 0;
  right: 0;
  // width: $header-width;
  width: 100vw;
  height: $header-height;
  @include animation(0s, 0.7s, 1, forwards, slidein_up);
  @include flexTo(row, flex-start, center);

  .backBtnBox {
    @include flexTo(row, center, center);
    width: $menu-width;
    height: 3vw;
    font-size: 1vw;
    user-select: none;

    // background: $dark-layer1-background;
    > span {
      cursor: pointer;
      @include flexTo(row, center, center);

      &:hover {
        color: $dark-active-font;
      }
    }
  }

  .tabgroup {
    @include flexTo(row, flex-start, center);
    height: 100%;
    width: $header-width;
    overflow-x: auto;

    > div {
      @include borderBox(1px, $dark-inactive-font);
      border-width: 0px 1px 0px 0px;
      width: 5vw;
      &:nth-child(1) {
        border-left: 1px $dark-inactive-font solid;
      }
      @include flexTo(row, space-around, center);
      flex-shrink: 0;
      cursor: pointer;
      width: 10vw;
      height: 100%;

      .fileInfo {
        box-sizing: border-box;
        @include scrollInvisible();
        white-space: nowrap;
        overflow: scroll;
        border-left: 0.35rem solid;
        width: 80%;
        height: 43%;
        padding-left: 0.5rem;
        @include flexTo(row, flex-start, center);

        svg {
          flex-shrink: 0;
          margin-right: 5px;
        }
      }
      .closeIcon {
        user-select: none;
        color: lightgray;
        @include flexTo(row, center, center);
        &:hover {
          color: $dark-active-font;
        }
      }
    }
  }

  .infogroup {
    position: absolute;
    right: 0;
    @include flexTo(row, center, center);
    height: 100%;
    width: 13%;
    // border:1px solid red;
    display: flex;
    gap: 1vw;
  }
}

@include keyframe(slidein_up) {
  0% {
    top: -$header-height;
  }
  100% {
    top: 0;
  }
}

@keyframes spinLoader {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

.product {
  display: flex;
  width: 9vw;
  justify-content: space-around;
  align-items: center;
  > div {
    > img {
      width: 1.5vw;
    }
  }
}
