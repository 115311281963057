@import '3_styles/_variables';
@import '3_styles/_mixins';

%span-style {
  height: 1.7vw;
  @include flexTo(row, center, center);

  &.reactIcon {
    margin: 0px 2px;
    cursor: pointer;
    @include borderBox(1px, transparent);
    filter: drop-shadow(4px 4px 4px #000);
    &:active {
      background: $dark-default-background;
    }
  }
}

.viewController {
  background: #34383c;
  height: 2vw;
  @include flexTo(row, center, stretch);

  > div {
    border-left: 2px $dark-default-background solid;
    border-right: 2px $dark-default-background solid;
    @include flexTo(row, center, center);

    > span {
      @extend %span-style;
    }
  }
}

.overlayOnIcon {
  width: 1.3vw;
}

.mip {
  width: 20%;
  border-left: none;
  display: flex;
  gap: 0.5vw;
  @extend %span-style;
}

.overlay {
  width: 25%;
  display: flex;
  gap: 0.5vw;
}

.intensity {
  width: 20%;
  display: flex;
  gap: 0.5vw;
}

.btnGroup {
  width: 10%;
  display: flex;
  gap: 1vw;
}

.burger {
  width: 5%;
  border-right: none;
}

.inputRange {
  height: 1.7vw;
  width: 8vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  > div {
    width: 90%;
    // border:1px red solid;
  }
}
