.watermark-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.watermark {
  position: absolute;
  top: 50%;
  left: 50%;
  // transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%);
  width: 70%;
  height: auto;
  opacity: 0.1;
}

// .watermark-text {
//   position: absolute;
//   bottom: 40px; /* 페이지 하단으로부터의 거리 조정 */
//   right: 20px; /* 페이지 우측으로부터의 거리 조정 */
//   font-size: 12px; /* 텍스트 크기 조정 */
//   color: #ffffff; /* 텍스트 색상 지정 */
//   opacity: 0.5; /* 텍스트의 투명도 조정 */
//   z-index: 100; /* 다른 요소 위로 띄움 */
// }
