@import '3_styles/_variables';
@import '3_styles/_mixins';

.d3Graph {
  @include flexTo(row, center, flex-start);
  // width: $table-width;
  // border:1px solid red;
  width: 100%;
  height: 240px;

  .bar {
    // fill: darkslateblue;
    fill: red;
    // border:1px black solid;
  }

  // .bar-label{
  //   text-anchor: end;
  // }
  // .axis-label {
  //   text-anchor: middle;
  //   font-size: 13px;
  // }
  .axis line {
    fill: none;
    stroke: black;
  }

  .axis path {
    display: none;
  }

  .axis text {
    font-size: 15px;
  }

  .axis {
    border: 1px red solid;
    // color:red;
  }
}
