@import '3_styles/variables';
@import '3_styles/_mixins';

$worklist-box-width: 60vw;
// $worklist-box-height: 42vw;
$worklist-handle-width: 1.5vmax;
$worklist-handle-height: 6vmax;

.bg_worklist {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;
  border: 1px transparent solid;
}

.container {
  z-index: 2;
  // @include borderBox(1px, yellow);
  position: fixed;
  top: 3vw;
  bottom: 3vw;
  width: $worklist-box-width;
  // height: $worklist-box-height;
  transition: all 0.5s ease;
  &.opened {
    right: 0;
  }
  &.closed {
    right: -$worklist-box-width;
  }
  @include flexTo(row, flex-start, center);
  .worklistHandle {
    background: $dark-layer1-background;
    position: absolute;
    top: 50%;
    transform: translate(-99%, -50%);
    // left:-50px;
    width: $worklist-handle-width;
    height: $worklist-handle-height;
    // @include borderBox(1px, transparent);
    // @include borderBox(1px, blue);
    border-radius: 10px 0 0 10px;
    cursor: pointer;
    border: 1px transparent solid;
    &:hover {
      border-color: $dark-active-font;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    div {
      text-align: center;
      transform: rotate(270deg);
    }
  }
  .worklistBox {
    background: $dark-layer1-background;
    width: 100%;
    height: 100%;
    @include borderBox(1px, transparent);
    border-radius: 10px 0 0 10px;
    @include flexTo(column, flex-start, stretch);
    padding: 5% 2.5%;
    .head {
      // @include borderBox(1px, yellow);

      padding: 1vw 0;
      display: flex;
      justify-content: space-between;
      > div:nth-child(1) {
        font-size: 1.2vw;
      }
      > div:nth-child(2) {
        //burger
        background-color: #4d5055;
        height: 2vw;
        width: 2vw;
        border-radius: 3px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          outline: $dark-active-font;
        }
        > img {
          width: 50%;
          height: 50%;
        }
      }
    }
    .body {
      // @include borderBox(2px, red);
      height: 100%;
      overflow: auto;
      width: 100%;
      &::-webkit-scrollbar {
        width: 0.5vw;
        background-color: transparent;
        // border:1px white solid;
        background: $dark-layer0-background;
      }
      &::-webkit-scrollbar-track {
        margin-top: 2.5vw;
        // padding:2vw;
        background-color: $dark-layer2-background;
        height: 80%;
      }
      &::-webkit-scrollbar-thumb {
        // width:50%;
        height: 30%;
        background-color: gray;
        border-radius: 10px;
        margin-top: 2vw;
      }
    }
    .foot {
      // @include borderBox(1px, yellow);
      @include flexTo(row, flex-start, flex-start);
      gap: 1vw;
      justify-content: space-between;
      > div:nth-child(2) {
        // @include borderBox(1px, red);
        display: flex;
        gap: 1vw;
      }
    }
  }
}

// .table {
//   user-select: none;
//   cursor: pointer;
//   border-collapse: collapse;
//   border: 1px yellow solid;
//   width: 100%;
//   text-align: center;
//   > thead {
//     background-color: #313438;
//     height: 2.5vw;
//     > tr {
//       border: 1px green solid;
//       > th {
//         font-weight: 400;
//         font-size: calc(6px + 0.5vw);
//         > img {
//           width: 0.7vw;
//           height: 0.4vw;
//           margin-left: 0.5vw;
//         }
//       }
//     }
//   }
//   > tbody {
//     // background-color: rgba(56, 60, 65, 0.5);
//     > tr {
//       height: 2.5vw;
//       // font-weight: bold;
//       // outline: 1px white solid;
//       // font-size: 1vw;
//       border-top: 1px solid #797a7c;
//       &:hover {
//         background-color: $dark-hover;
//       }
//       > td {
//         > img {
//           // border: 1px solid red;
//           height: 0.75vw;
//           width: 0.75vw;
//         }
//         font-weight: 300;
//         font-size: calc(6px + 0.5vw);
//       }
//     }
//   }
// }
