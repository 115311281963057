@import '3_styles/_variables';
@import '3_styles/_mixins';

:export {
  spinner-color: $dark-active-font;
}

.container {
  @include flexTo(row, center, center);
  // @include borderBox(1px, red);
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  color: white;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 2;
  > .exitButton {
    @include exitButton();
  }
  > .spinnerImg {
    // border:1px red solid;
    // width: 30vmin;
    > svg,
    img {
      width: 100%;
      animation: spinLoader 3500ms steps(300, end) infinite;
    }
  }
  > .msgText {
    // border:1px red solid;
    font-size: 3vmin;
    position: absolute;
    top: 38%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .msgText_percent {
    // border:1px red solid;
    font-size: 3vmin;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .msgText_count {
    // border:1px red solid;
    font-size: 2vmin;
    font-weight: 300;
    position: absolute;
    top: 61%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@keyframes spinLoader {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

.pacsCounter {
  // border:1px solid red;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > span:nth-child(1) {
    font-size: 3vmin;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  > span:nth-child(2) {
    font-size: 3vmin;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  > span:nth-child(3) {
    font-size: 2vmin;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

// .pacsCounter_checkTime{

// }

.msgText_report {
  font-size: 3vmin;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.msgText_complete {
  font-size: 3vmin;
  position: absolute;
  top: 37%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.msgText_check {
  position: absolute;
  top: 50.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  > img {
    width: 3.5vw;
  }
}

.uploadingProgress {
  position: absolute;
  width: 20vw;
  top: calc(50% + 5vw);

  display: flex;
  flex-direction: column;

  .messageContainer {
    height: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .msgText {
      font-size: 15px;
    }

    .count {
      font-size: 13px;
    }
  }

  .progressBarContainer {
    background-color: $dark-layer2-background;
    position: relative;
    border-radius: 8px;
    height: 23px;

    .progressBarBody {
      background-color: $dark-active-font;
      position: absolute;
      top: 0;
      bottom: 0;
      border-radius: 8px;
      width: 100%;
      box-sizing: border-box;
    }
    .progressBarPercent {
      position: absolute;
      top: 1px;
      left: 16px;
    }

    .isConverting {
      background-size: 30px 30px;
      background-image: linear-gradient(
        135deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent
      );
      animation: animate-stripes 1s linear infinite;
    }
  }
  .progressBarText {
    height: 25px;

    .message {
      font-size: 13px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

@keyframes animate-stripes {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 60px 0;
  }
}
