@import '3_styles/_variables';
@import '3_styles/_mixins';

.container {
  background-color: $dark-layer2-background;
  height: 1.5vw;
  width: 1.5vw;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
}

.burger_container {
  // border: 1px solid red;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  > img {
    display: flex;
    width: 50%;
    height: 50%;
  }
}

.list_container {
  // position: sticky;
  // border: 1px solid greenyellow;
  z-index: 1;
  position: absolute;
  cursor: pointer;
  top: 110%;
  right: 0;
  background: $dark-layer2-background;
  width: 600%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > div {
    // border: 1px solid red;
    width: 100%;
    height: 1.5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.7vw;
    &:hover {
      background: $dark-hover;
    }
  }
}

.view_burger {
  // background-color: $dark-layer2-background;
  height: 1.5vw;
  width: 1.5vw;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  width: 1.7vw;
  cursor: pointer;
  > div {
    > img {
      filter: drop-shadow(4px 4px 4px #000);
    }
  }
}

.view_dropdown {
  // border:1px solid red;
  z-index: 1;
  position: absolute;
  cursor: pointer;
  top: 130%;
  right: -2.9vw;
  background: $dark-layer1-background;
  width: 600%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > div {
    // border: 1px solid red;
    width: 100%;
    height: 1.5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.7vw;
    &:hover {
      background: $dark-hover;
    }
  }
}
