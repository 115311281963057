@import '3_styles/_variables';
@import '3_styles/_mixins';

.table_content {
  position: relative;
  max-height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  white-space: nowrap;

  &::-webkit-scrollbar {
    width: 0.3vw;
    background-color: transparent;
    background: $dark-default-background;
  }
  &::-webkit-scrollbar-thumb {
    height: 30%;
    background-color: gray;
    border-radius: 10px;
    margin-top: 2vw;
  }
  &::-webkit-scrollbar-track {
    margin-top: 2vw;
    background-color: $dark-layer2-background;
    height: 80%;
  }
}

.loading {
  overflow: hidden;

  > .loadIcon {
    width: 100%;
    user-select: none;
    @include flexTo(row, center, center);
    position: relative;
    img {
      animation: spinLoader 1500ms steps(300, end) infinite;
    }
  }
  > .loadMsg {
    // @include borderBox(3px, blue);
    font-size: 2vw;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }
}
@keyframes spinLoader {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}
