// PacsModal, SendDicomModal, UploadModal 에서 공통으로 사용되고 있음.
// uploadingSteps 에서 사용되고 있음.

@import '3_styles/_variables';
@import '3_styles/_mixins';
$icon-size: 0.8vw;
:export {
  icon-size: $icon-size;
  icon-color: $dark-active-font;
}
@mixin optionText() {
  // padding-top: 0.2vw;
  user-select: none;
  @include flexTo(row, center, center);
  > div {
    font-size: $icon-size;
  }
}
@mixin nextButton() {
  // margin-top: 20px;
  // margin: auto;
  margin: 0.5%;
  width: 8vw;
  height: 2vw;

  // font-size: 1vw;
  &:hover {
    background: $dark-active-font;
  }
  color: white;
  font-weight: 400;
  font-size: 0.7vw;
  @include flexTo(row, center, center);
  @include borderBox(1px, white);
  border-radius: 5px;
  user-select: none;
  cursor: pointer;
}

.container {
  @include flexTo(row, center, center);
  // @include borderBox(1px, red);
  background: rgba(0, 0, 0, 0.5);
  color: white;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 2;
  > .exitButton {
    @include exitButton();
  }
  > .stepContainer {
    // @include borderBox(3px, green);
    width: 65%;
    height: 70%;
    min-height: 40vw;
    max-height: 70vw;
    overflow: hidden;
    display: flex;
    flex-shrink: 0;
    // position:relative;
  }
}
