@import '3_styles/_variables';
@import '3_styles/_mixins';

:export {
  btnColor: $dark-layer1-background;
}

.leftBtn {
  // border: 1px solid yellow;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5vw;
  height: 2vw;
  width: 8vw;
  cursor: pointer;
  border-radius: 3px;
  background-color: $dark-layer1-background;
  &:hover {
    outline: 1px solid $dark-active-font;
  }
  > div:nth-child(1) {
    // border: 1px solid red;
    display: flex;
    > img {
      height: 1.5vw;
      width: 1.5vw;
    }
  }
}

.deleteBtn {
  // border: 1px solid yellow;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5vw;
  height: 2vw;
  width: 8vw;
  cursor: pointer;
  border-radius: 3px;
  background-color: $dark-layer1-background;
  &:hover {
    outline: 1px solid $dark-active-negative-font;
  }
  > div:nth-child(1) {
    // border: 1px solid red;
    display: flex;
    > img {
      height: 1.5vw;
      width: 1.5vw;
    }
  }
}

.rightBtn {
  // border: 1px solid yellow;
  @include flexTo(row, center, center);
  gap: 0.5vw;
  height: 2vw;
  width: 8vw;
  cursor: pointer;
  border-radius: 3px;
  background-color: $dark-layer1-background;
  position: relative;
  // >div{
  //   // border:1px red solid;
  //   // transition: all 0.3s ease;
  //   box-sizing:border-box;
  //   &:hover {
  //     // outline: 1px solid $dark-active-font;
  //     border:1px $dark-active-font solid;
  //   }
  // }
  // &:hover{
  //   border:1px $dark-active-font solid;
  //   box-sizing:border-box;
  // }
  > label {
    cursor: pointer;
  }
  > div {
    cursor: pointer;
    transition: top 0.3s ease;
    border-radius: 3px;
    box-sizing: border-box;
    background-color: $dark-layer1-background;
    // border: 1px solid red;
    @include flexTo(row, center, center);
    > img,
    svg {
      // border: 1px solid red;
      height: 1.1vw;
      width: 1.1vw;
      cursor: pointer;
    }
    > label {
      border-radius: 3px;
      // border:1px red solid;
      cursor: pointer;
      width: 100%;
      height: 100%;
      @include flexTo(row, center, center);
      // box-sizing:border-box;
      &:hover {
        border: 1px $dark-active-font solid;
        box-sizing: border-box;
      }
    }
  }
}

.worklistBtn {
  // border: 1px solid yellow;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5vw;
  height: 2vw;
  width: 8vw;
  cursor: pointer;
  border-radius: 3px;
  background-color: $dark-layer2-background;
  &:hover {
    outline: 1px solid $dark-active-font;
  }
  > div:nth-child(1) {
    // border: 1px solid red;
    display: flex;
    > img {
      height: 1.5vw;
      width: 1.5vw;
    }
  }
}
