@import '3_styles/_variables';
@import '3_styles/_mixins';

.A4pages {
  // @include borderBox(5px, red);
  width: 210mm;
  height: 297mm;

  padding: 20mm;
  margin: 10mm auto;
  // border: 1px #D3D3D3 solid;
  // border: 4px cyan solid;
  background: white;
  color: black;
  // position: relative;

  // bootstrap style reset
  box-sizing: content-box;
}
