@import '3_styles/_variables';
@import '3_styles/_mixins';

:export {
  pressedColor: $dark-default-background;
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  @include flexTo(column, flex-start, stretch);
}

.viewGroup {
  overflow: auto;
  @include scrollInvisible;
}
