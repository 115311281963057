@import '3_styles/_variables';
@import '3_styles/_mixins';

%Page1Title {
  // .Page1Title {
  // @include borderBox(1px, blue);
  @include flexTo(row, flex-start, center);
  color: $report-title;
  font-size: 20px;
  margin: 10px 0;
  font-weight: bold;

  > img {
    width: 30px;
  }
}

.PatientInfoContainer {
  @include flexTo(column, center, stretch);
  // @include borderBox(3px, blue);
  margin-top: 25px;
  > .ReportTitle {
    @extend %Page1Title;
  }

  > .PatientInfoTable {
    margin-left: 5%;

    // @include borderBox(1px, red);
    div,
    span,
    td {
      font-size: 17px;
    }

    td {
      &.svgImage {
        // border:1px red solid;
      }

      &.colorBar {
        border: none;
      }
    }
  }
}

.BrainTableContainer {
  @include flexTo(column, center, flex-start);
  // @include borderBox(3px, blue);
  height: 100%;
  overflow: hidden;

  // height:50%;
  > .ReportTitle {
    @extend %Page1Title;
  }

  > .BrainTable {
    // width: 80%;
    // border:1px red solid;
    display: flex;
    justify-content: center;
    // margin: 0 auto;
    // margin-left: 5%;
    // width: 90%;
    * {
      font-size: 18px;
    }
    > div {
      width: 90%;
    }
  }
}
