@import '3_styles/_variables';
@import '3_styles/_mixins';

// .container {
//   position: relative;
//   // padding: 2vw;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   width: 50%;
//   height: 100%;
//   max-height: 80vw;
//   border: 1px yellowGreen solid;
//   box-sizing: border-box;
//   // background-color: black;
// }

.top {
  padding: 1vw 0 0 3vw;
  // border: 1px red solid;
  font-size: calc(6px + 0.5vw);
}
