@import '3_styles/_variables';
@import '3_styles/_mixins';
.ReportLayoutContainer {
  // background: transparent url("../../../../6_media/png/frpo.png") no-repeat;
  // background-position: center center;
  position: relative;
  // @include borderBox(4px, yellow);
  height: 100%;
  @include flexTo(column, space-between, stretch);
  .top {
    // @include flexTo(row, space-between, flex-start);
    display: flex;
    flex-direction: row;
    justify-content: end;
    width: 100%;
    // border:1px red solid;
    position: absolute;
    transform: translate(0, -120%);

    .researchInfo {
      width: 80%;
      font-weight: 500;
      font-size: 14px;
      font-style: italic;
      color: #b3b3b3;
      text-align: left;
      font-weight: 400;
      font-size: 14px;
      color: #b3b3b3;
    }

    .date {
      // border:1px solid red;
      width: 20%;
      text-align: right;
      font-weight: 400;
      font-size: 14px;
      color: #b3b3b3;
    }

    margin-bottom: 1rem;
  }

  // .researchInfo{
  //       border:1px solid blue;
  //       width: 80%;
  //       font-weight: 500;
  //       font-size: 14px;
  //       font-style: italic;
  //       color: #b3b3b3;
  //       text-align: start;
  // }

  .head {
    // @include borderBox(1px, black);
    // position:absolute;
    // top:0;
    > .title {
      // @include borderBox(1px, black);
      border-left: 0.5rem $report-title solid;
      padding: 1rem 2rem;
      @include flexTo(column, flex-start, flex-start);
      display: flex;
      flex-direction: column;
      gap: 10px;
      > span {
        &:nth-child(1) {
          // border:1px solid blue;
          font-weight: 700;
          font-size: 36px;
        }
        &:nth-child(2) {
          font-weight: 400;
          font-size: 20px;
        }
      }
    }
  }

  .body {
    // background: url("../../../../6_media/png/frpo.png") no-repeat;
    // background-position: center center;
    // border: 5px red solid;
    height: 100%;
    overflow: hidden;
    @include flexTo(column, flex-start, stretch);
    // > div {
    //   // border: 1px red solid;
    // }
    position: relative;
    > .overlay {
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // background: url("../../../../6_media/png/frpo.png") no-repeat;
      background-position: center center;
    }
  }

  .body_spect {
    // background: url("../../../../6_media/png/frpo.png") no-repeat;
    // background-position: center center;
    // border: 5px red solid;
    height: 100%;
    overflow: hidden;
    @include flexTo(column, flex-start, stretch);
    // > div {
    //   // border: 1px red solid;
    // }
    position: relative;
    > .overlay {
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // background: url("../../../../6_media/png/frpo.png") no-repeat;
      background-position: center center;
    }
  }

  .foot {
    // @include borderBox(1px, black);
    @include flexTo(column, flex-start, flex-start);
    // position: absolute;
    // bottom:0;
    > span:nth-child(1) {
      font-weight: 500;
      font-size: 14px;
      color: #b3b3b3;
    }
    > span:nth-child(2) {
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      color: #b3b3b3;
    }
  }

  .bottom {
    position: absolute;
    bottom: 0;
    transform: translate(0, 120%);
    // border: 1px red solid;
    @include flexTo(row, space-between, center);
    width: 100%;
    > span:nth-child(1) {
      > img {
        width: 250px;
        height: 20px;
      }
    }
    > div:nth-child(2) {
      @include flexTo(column, center, flex-end);
      // border:1px red solid;
      > span {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #b3b3b3;
      }
    }
  }
}
