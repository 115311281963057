@import '3_styles/_variables';
@import '3_styles/_mixins';

:export {
  dark-layer1-background: $dark-layer1-background;
}

.container {
  // border: 3px yellow solid;
  box-sizing: border-box;
  background: $dark-default-background;

  position: relative;
  top: $header-height;
  left: $menu-width;
  width: calc(100vw - $menu-width);
  height: calc(100vh - $header-height);
  overflow: auto;
}

.reportContainer {
  // border: 3px yellow solid;
  z-index: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  overflow-y: scroll;
}

.buttons {
  position: fixed;
  top: 0;
  right: 2rem;
  text-align: right;
  margin: 2rem 2rem 0 0;

  // @include borderBox(1px, red);
  > span {
    font-size: 3rem;
    cursor: pointer;

    &:hover {
      color: $dark-active-font;
    }
  }
}
