@import '3_styles/_variables';
@import '3_styles/_mixins';

.Container {
  // border:1px solid red;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.imgContainer {
  // border:1px solid yellow;
  height: 85%;
}

.imgContainer_subContainer {
  // border:1px solid white;
  display: flex;
  height: 50%;
  > div {
    // border:1px solid blue;
    width: 50%;
  }
}

.imgBox {
  // border:1px solid red;
  display: flex;
  flex-direction: column;
  align-items: center;
  > img {
    width: 65%;
  }
}

.indicatorContainer {
  // border:1px solid green;
  display: flex;
  justify-content: center;
  gap: 1vw;
  > div:nth-child(3) {
    // border:1px solid red;
    width: 2vw;
  }
}
