@import '3_styles/_variables';
@import '3_styles/_mixins';

:export {
  activeColor: $dark-active-font;
}

.imageWrapper {
  > div {
    @include flexTo(row, center, flex-start);

    &.divUpper {
      > div {
        overflow: hidden;
        height: 100%;
      }
    }

    &.divLower {
      > div {
        overflow: hidden;
        height: 100%;
      }
    }
  }
}
