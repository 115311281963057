@import '3_styles/_variables';
@import '3_styles/_mixins';

.container {
  // @include borderBox(4px, yellow);
  // box-sizing: border-box;
  width: 100%;
  font-size: 0.8vw;
  margin: auto;
  overflow-y: auto;
  // display:flex;
  // align-items:center;
  // @include flexTo(row, flex-start, center);
  align-self: flex-start;
}

.itemsContainer {
  // @include borderBox(1px, red);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1.4vw;
  margin: auto;
  gap: 1.2vw;
  // width: 60%;
  // background-color: #34383c;
}

.items {
  display: flex;
  gap: 1vw;
  > div:nth-child(1) {
    > img {
      width: 1vw;
      height: 1vw;
      cursor: pointer;
    }
  }
  > div:nth-child(2) {
    padding-left: 0.5vw;
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 0.8vw;
  }
}
