@import '3_styles/_variables';
@import '3_styles/_mixins';

.pass-btn {
  @extend %btn__common;
  margin-top: 2vw;
}

.logout-btn {
  @extend %btn__common;
  margin-top: 2vw;
  > button {
    background-color: $dark-layer1-background;
  }
}

.signup-btn,
.forgot-btn {
  @extend %btn__common;
  margin-top: 2vw;
  height: 2vw;
  > button {
    font-size: 0.7vw;
    line-height: 2vw;
    height: 2vw;
  }
}
