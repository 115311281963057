@import '3_styles/_variables';
@import '3_styles/_mixins';

.container {
  width: 100%;
  margin: 10px 0 15px 0;
  display: flex;
  justify-content: space-around;
}

.infoContainer {
  display: flex;
  justify-content: center;
  gap: 4vw;
}

.info-box {
  display: flex;
  justify-content: stretch;
  gap: 0.5vw;
  // height: 55px;

  > div {
    min-width: 60px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 13px;
    text-align: center;
    font-size: 0.7vw;
    max-width: 8vw;
    overflow: hidden;

    > div:nth-child(1) {
      font-weight: 230;
      white-space: nowrap;
    }
    > div:nth-child(2) {
      font-weight: 400;
      overflow: hidden;

      div {
        white-space: nowrap;
        overflow: visible;
      }

      &.Tracer {
        span {
          height: 1.1vw;
          padding: 0 10px;
          display: inline-block;
        }
      }
    }
  }

  .wrapper > span {
    font-size: 15px;
  }
}

.pInfo {
  @extend .info-box;

  > div:nth-child(2),
  > div:nth-child(7) {
    width: 200px;
  }

  .Patient_name,
  .Desc {
    .wrapper:hover {
      overflow: visible;
      white-space: nowrap;
      animation-name: marquee;
      animation-timing-function: linear;
      animation-duration: 5s;
      animation-iteration-count: infinite;
    }
  }
}

.ref_indicator {
  @extend .info-box;

  gap: 2vw;

  > div {
    white-space: nowrap;
    max-width: fit-content;
  }
}

@keyframes marquee {
  from {
    transform: translate(0%);
  }
  99%,
  to {
    transform: translate(-100%);
  }
}

.burger {
  width: 10%;
  display: flex;
  justify-content: end;
  align-items: center;
}
