@import '3_styles/_variables';
@import '3_styles/_mixins';

.container {
  // @include borderBox(1px, yellow);
  width: 80%;
  margin: auto;
  // @include flexTo(column, flex-start, start);
  align-self: flex-start;
  gap: 1vw;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // gap: 2vw;
  // overflow-y:auto;
  // overflow-x:hidden;
  // @include flexTo(column, flex-start, flex-start);
  // flex-flow: row wrap;
}

.checkList {
  display: flex;
  flex-direction: column;
  // border: 1px solid cyan;
  justify-content: center;
  gap: 0.5vw;
  width: 75%;
  > div {
    height: 2vw;
    padding: 0 0.5vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $dark-default-background;
    border-radius: 3px;
    cursor: pointer;
    > div:nth-child(2) {
      > img {
        width: 1vw;
        height: 1vw;
      }
    }
  }
}

.title {
  // border: 1px solid red;
  width: 100%;
  display: flex;
  gap: 0.5vw;
  > div:nth-child(1) {
    height: 100%;
    > img {
      cursor: pointer;
      width: 1vw;
      height: 1vw;
    }
  }
  > div:nth-child(2) {
    font-weight: 400;
    font-size: 0.8vw;
  }
}

.optionContainer {
  // border:1px solid red;
  width: 100%;
}
.options {
  // border:1px solid yellow;
  padding: 1vw 0 0 0;
  display: flex;
  width: 100%;
  gap: 2vw;
}

.option {
  // border:1px solid red;
  width: 7vw;
  height: 2vw;
  background-color: #292d30;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5vw 0 0.5vw;
  font-style: normal;
  font-weight: 300;
  font-size: 0.8vw;
  border-radius: 3px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  > img {
    width: 1vw;
    height: 1vw;
  }
}
