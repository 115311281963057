// Bootstrap dark theme variables override
$body-bg: #343a40;
$body-color: #ffffff;
$link-color: #ffffff;
$link-hover-color: #adb5bd;

$theme-colors: (
  'primary': #118af7,
  'secondary': #6c757d,
  'success': #28a745,
  'info': #17a2b8,
  'warning': #ffc107,
  'danger': #dc3545,
  'light': #f8f9fa,
  'dark': #343a40,
);

// Import Bootstrap's SCSS
@import '~bootstrap/scss/bootstrap';

// Custom styles for Pagination focus state
.pagination {
  margin-bottom: 0;
}
.page-link.active,
.active > .page-link {
  z-index: inherit;
  background-color: map-get($theme-colors, 'primary');
}
.page-item:focus,
.page-item:focus-visible,
.page-item:focus-within {
  --bs-pagination-focus-box-shadow: none;
  box-shadow: none;
}
.page-item .page-link {
  border: 0 solid;
}

// drop down menu
.dropdown-menu {
  min-width: auto;
  text-align: center;
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: $body-bg;
}

// input group
.input-group {
  width: inherit;
}
.input-group > input {
  border-color: #6c757d;
}

// form button
.btn-primary {
  color: white;
}
