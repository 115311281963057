@import '3_styles/_variables';
@import '3_styles/_mixins';

.container {
  background: $dark-default-background;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include flexTo(column, center, center);
  // @include borderBox(1px, red);
  > div:nth-child(1) {
    width: 45%;
    height: 100%;
    // @include borderBox(1px, red);
    @include flexTo(column, center, left);
  }
}

.pass-btn {
  @extend %btn__common;
}

.logout-btn {
  @extend %btn__common;
  > button {
    background-color: $dark-layer2-background;
  }
}
.title {
  font-size: 1.5vw;
  font-weight: 500;
}
.product {
  // border: 1px solid yellow;
  @include flexTo(column, center, end);
  height: 2vw;

  > select {
    width: 30%;
    height: 80%;
  }
}

.forgot-password {
  // border:1px solid red;
  font-size: 0.7vw;
  font-weight: 200;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: $dark-active-font;
  }
}

.license {
  @include flexTo(column, center, center);
  margin-top: 0.5vw;
  color: $dark-active-font;
  &.auth {
    color: $dark-active-font;
  }
  &.fails {
    color: red;
  }
}
