@import '3_styles/_variables';
@import '3_styles/_mixins';
@import '5_components/2_Modal/UploadingModal.module.scss';

.boxContainer {
  transition: all 0.3s ease;
  // border:5px blue solid;
  position: relative;
  min-width: 100%;
  height: 100%;
  padding: 3% 3% 3% 3%;
  box-sizing: border-box;
  background: $dark-layer1-background;
  @include flexTo(column, flex-start, flex-start);
  > .backButton {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 0.8vw;
    margin: 0.5vw 1vw;
    // font-weight: 600;
    user-select: none;
    cursor: pointer;
    &:hover {
      color: $dark-active-font;
    }
  }
  > .boxTitle {
    padding-top: 1vw;
    // border: 1px red solid;
    font-size: 2.2vw;
    margin: auto;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    width: 100%;
    > div {
      // @include borderBox(1px, red);
      // margin-right:8px;
      width: 14vw;
      &:nth-child(2) {
        width: 18vw;
        // color:red;
      }
      > label {
        font-size: 1.1vw;
        // display:block;
        > input {
          font-size: 1vw;
          display: block;
          width: 92%;
        }
      }
    }
    > .searchBtn {
      width: 9vw;
      @include flexTo(row, center, center);
      font-size: 1.1vw;
      background: $dark-layer2-background;
      user-select: none;
      cursor: pointer;
      &:hover {
        background: $dark-active-font;
      }
    }
  }
  > .boxContent {
    // @include borderBox(1px, red);
    // border: 1px red solid;
    // background: $dark-layer1-background;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    @include flexTo(row, flex-start, flex-start);
    // flex-shrink:0;
    position: relative;
    > table {
      // border:4px blue solid;
      box-sizing: border-box;
      width: 60%;
      // min-width:100%;
    }
    > .previewImg {
      // border:4px red solid;
      position: sticky;
      top: 0%;
      right: 0%;
      transition: all 0.3s ease;
      // &.active{
      //   right:0;
      //   border:4px blue solid;
      // }
      // &.inactive{
      //   right:-50%;
      //   border:4px red solid;
      // }
      box-sizing: border-box;
      width: 40%;
      height: 100%;
      transform: translate(0, 0%);
      // min-width:35%;
      @include flexTo(row, flex-start, flex-start);
      flex-wrap: wrap;
      align-content: center;

      > div {
        // border:1px red solid;
        box-sizing: border-box;
        width: 50%;
        height: 50%;
        @include flexTo(row, center, center);
        background: black;
        overflow: hidden;
        // >img{
        // border: 1px white solid;
        // box-sizing:border-box;
        // }
        &:nth-child(1) {
          > img {
            min-height: 60%;
            // width:100%;

            &.verticalFlip {
              transform: scaleY(-1);
            }
          }
        }
        &:nth-child(2) {
          > img {
            min-height: 60%;
            // width:100%;
            &.verticalFlip {
              transform: scaleY(-1);
            }
          }
        }
        &:nth-child(3) {
          > img {
            // min-height:80%;
            min-height: 95%;
            // max-height:100%;
            // min-width:100%;
          }
        }
      }
    }
  }
  > .buttonGroup {
    width: 100%;
    @include flexTo(column, center, center);
    > .optionText {
      @include optionText();
    }
    > .nextButton {
      @include nextButton();
    }
  }
}
