@import '3_styles/_variables';
@import '3_styles/_mixins';
:export {
  default-font-color: $dark-default-background;
}
.btnContainer {
  margin: 3vw 3vw 2vw 3vw;
  // border: 1px solid red;
  display: flex;
}

.btnGroup1 {
  // border: 1px solid white;
  display: flex;
  gap: 0.5vw;
  align-items: center;
}

.btnGroup2 {
  // border: 1px solid white;
  display: flex;
  gap: 0.5vw;
  align-items: center;
  margin-left: 2.5vw;
}

.btnGroup3 {
  // border: 1px solid white;
  display: flex;
  gap: 0.5vw;
  align-items: center;
  margin-left: 2.5vw;
}

.btnGroup4 {
  // border: 1px solid white;
  display: flex;
  gap: 0.5vw;
  align-items: center;
  margin-left: auto;
}

.tableContainer {
  margin-left: 3vw;
  padding: 0 3vw 0 0;
  // border: 1px solid red;
  width: 100%;
  display: flex;
  flex-direction: column;
}

// .table {
//   user-select: none;
//   cursor: pointer;
//   border-collapse: collapse;
//   border: 1px yellow solid;
//   width: 100%;
//   text-align: center;
//   > thead {
//     background-color: $dark-layer1-background;
//     height: 2.5vw;
//     > tr {
//       border: 1px green solid;
//       > th {
//         font-weight: 400;
//         font-size: calc(6px + 0.5vw);
//         > img {
//           width: 0.9vw;
//           height: 0.9vw;
//           margin-left: 0.5vw;
//         }
//       }
//     }
//   }
//   > tbody {
//     background-color: rgba(56, 60, 65, 0.5);
//     > tr {
//       height: 2.5vw;
//       // font-weight: bold;
//       // outline: 1px white solid;
//       // font-size: 1vw;

//       &:hover {
//         background-color: $dark-hover;
//       }
//       > td {
//         font-weight: 300;
//         font-size: calc(6px + 0.5vw);
//       }
//     }
//   }
// }
