@import '3_styles/_variables';
@import '3_styles/_mixins';

:export {
  activeColor: $dark-active-font;
}

.colorbarContainer {
  margin: auto 2vw;
  height: 70%;
  width: 3vw;
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 10px;

  &.isSlimMode {
    margin: auto 0;
    width: 1.5vw;
    height: 100%;
    align-items: center;

    // .refreshButton {
    //   margin: 10px 5px;
    // }
  }

  &.horizontal {
    margin: 2vw auto 0;
    height: 1.5vw;
    width: 100%;
    min-height: initial;

    flex-direction: row;
  }

  &.isSlimMode.horizontal {
    margin: 0 auto;
  }

  .labelColorbarBox {
    position: relative;
    height: 100%;
    width: 100%;

    &.horizontal {
      width: 100%;
      height: 100%;
    }
  }

  .lableArea {
    height: 100%;
    width: 100%;
    position: absolute;

    &.horizontal {
      height: 0;
      width: 100%;
    }

    .colorbarLabel {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: space-between;
      transform: translate(0%, -50%);
      left: 90%;
      width: 90%;
      user-select: none;
      pointer-events: none;

      &.horizontal {
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: center;
        top: -2vw;
        left: 0;
        width: 2vw;
        transform: translate(-1vw, 0%);
      }
    }
  }

  .colorbarDragArea {
    height: 100%;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    position: absolute;

    .colorbarBody {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      left: 0;
      width: 100%;
      border: 0px;
      box-sizing: border-box;
      transform: translateY(-50%);

      &.horizontal {
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // transform: translate(0%, -50%);
        transform: translateX(-50%);
      }
    }

    .colorbarArrow {
      height: 1vw;
      width: 1vw;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: row-resize;

      &.horizontal {
        cursor: col-resize;
      }

      &.head {
        transform: translateY(-50%);
      }
      &.tail {
        transform: translateY(50%);
      }

      & svg:hover {
        color: $dark-active-font;
      }

      &.horizontal.head {
        transform: translateX(50%);
      }
      &.horizontal.tail {
        transform: translateX(-50%);
      }
    }
  }

  .refreshButton {
    text-align: center;
    // margin: auto 10px;
    height: 1vw;
    width: 1vw;
    background: #34383c;
    border-radius: 3px;
  }
}
