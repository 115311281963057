@import '3_styles/_variables';
@import '3_styles/_mixins';

.container {
  background: #292d30;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include flexTo(column, center, center);
  // @include borderBox(1px, red);
  > div:nth-child(1) {
    width: 45%;
    height: 100%;
    // @include borderBox(1px, red);
    @include flexTo(column, center, left);
  }
}

.title {
  font-size: 1.2vw;
  font-weight: 500;
}

.CheckPassword {
  color: red;
  font-size: 0.5vw;
}
