@import '3_styles/_variables';
@import '3_styles/_mixins';
@import '../ReportPage1/ReportPage1.module.scss';

$table-width: 80%;

%Page3Title {
  align-self: stretch;
  @include flexTo(row, flex-start, center);
  color: $report-title;
  font-size: 20px;
  margin: 10px 0;
  font-weight: bold;

  > img {
    width: 24px;
  }
}

.skeletonDiv {
  position: relative;
  display: inline-block;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .skeletonText {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: 13px;
    color: #dedede;
    white-space: nowrap;
    text-align: center;
  }
}

.report3_content {
  margin-top: 25px;
  @include flexTo(column, flex-start, stretch);
  height: 100%;

  .ReportTitle {
    @extend %Page3Title;
  }

  .imageTitle {
    margin: 20px 0 10px 50px;
    font-weight: 600;
    font-size: 17px;
  }

  .imageWrapper {
    @include flexTo(row, space-between, center);

    .imageContent {
      @include flexTo(column, center, center);
      flex-grow: 1;

      .imageRow {
        @include flexTo(row, center, center);

        .imageBox {
          @include flexTo(column, center, center);

          img {
            max-width: 170px;
            width: 100%;
            height: 100%;
          }

          .skeletonDiv {
            width: 130px;
            height: 130px;
          }
        }
      }
    }

    .colorbarContainer {
      width: 70px;
      @include flexTo(column, center, center);

      .colorbar {
        width: 100%;
        height: 250px;
        @include flexTo(row, flex-end, center);

        img {
          width: 15px;
          height: 250px;
          border-radius: 10px;
        }

        .colorbarLegned {
          @include flexTo(column, space-between, flex-start);
          font-size: 12px;
          width: 65px;
          height: 100%;

          div {
            display: flex;
            align-items: center;
          }
        }
      }

      .colorbarLabel {
        font-size: 12px;
        margin-left: -56px;
      }
    }
  }
}

.MIPImageContainer {
  padding: 14px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  img {
    width: 190px;
  }

  .skeletonDiv {
    width: 160px;
    height: 160px;
  }

  // img {
  //   filter: invert(1);
  // }
}
