@import '3_styles/variables';
@import '3_styles/_mixins';

:export {
  active-color: $dark-active-font;
}

.CentiloidBar {
  // border:1px red solid;
  width: 90%;
  height: 3px;
  background: $dark-layer2-background;
  overflow: hidden;
  border-radius: 5px;
  > div {
    // border:1px red solid;
    background: red;
    height: 100%;
    border-radius: 5px;
  }
}
.TracerMarker {
  // background:red;
  // border:1px solid red;
  margin-left: 0.9vw;
  padding-left: 15px;
  text-align: start;
  width: 70%;
  border-left: 5px solid;
  box-sizing: border-box;
}

.table {
  // user-select: none;
  cursor: auto;
  border-collapse: collapse;
  // border: 4px yellow solid;
  width: 100%;
  text-align: center;
  table-layout: fixed;
  th,
  td:not(.Tracer) {
    // border:1px red solid;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    @include scrollInvisible;
    &.Marker {
      width: 4%;
      // border:1px red solid;
      transition: all 0.3s ease;
      &.active {
        transform: rotate(-270deg);
      }
      &.inactive {
        transform: rotate(-90deg);
      }
      // min-width:1000px;
    }
    &.Select {
      width: 6.5%;
      // min-width:1000px;
    }
    &.Tracer {
      width: 15%;
    }
    &.Centiloid {
      width: 7%;
    }
    &.SBR {
      width: 5%;
    }
    &.Patient_ID {
      width: 14%;
    }
    &.Patient_name {
      cursor: auto;
      width: 25%;
      // border:1px red solid;
      padding: 0 10px;
      overflow-x: auto;
      @include scrollInvisible();
    }
    &.DOB {
      width: 7%;
    }
    &.Modality {
      width: 8%;
    }
    &.Study_Description {
      cursor: auto;
      width: 30%;
    }
    &.Sex {
      width: 5%;
    }
    &.Study_Date {
      width: 10%;
    }
    &.Scan_date {
      width: 15%;
    }
    &.Uploaded_date {
      width: 15%;
    }
    &.Delete {
      width: 10%;
      > span {
        // border:1px red solid;
        font-size: 1.2vw;
        &:hover {
          color: $dark-active-font;
          cursor: pointer;
        }
      }
    }
  }
  > thead {
    position: sticky;
    top: 0;
    text-align: center;
    // background-color: #313438;
    background-color: $dark-layer2-background;
    // background-color: $dark-default-background;
    // background-color: red;
    height: 2.5vw;
    > tr {
      // border: 1px green solid;
      > th {
        font-weight: 600;
        // font-size: calc(6px + 0.5vw);
        > div {
          display: flex;
          align-items: center;
          justify-content: center;
          > span {
            height: 100%;
          }
          > div {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 0.2vw;
            > img {
              height: 0.8vw;
              width: 0.8vw;
            }
          }
        }
      }
    }
  }
  > tbody {
    // background-color: blue;
    // background-color: $dark-layer1-background;
    > .trMain {
      height: 2.5vw;
      border-top: 1px solid #797a7c;
      &:hover {
        background-color: $dark-hover;
      }
      > td {
        // border: 1px solid red;
        > img {
          // border: 1px solid red;
          height: 0.75vw;
          width: 0.75vw;
        }
        font-weight: 400;
        // font-size: calc(6px + 0.5vw);
      }
    }
    > .trSub {
      height: 2.5vw;
      // border-top: 1px solid #797a7c;
      background: $dark-default-background;
      &:hover {
        background-color: $dark-hover;
      }
      > td {
        font-weight: 400;
        // font-size: calc(6px + 0.5vw);
      }
      color: #cccccc;
      transition: all 0.1s ease;
      &.active {
        height: 2.5vw;
        opacity: 1;
        visibility: visible;
      }
      &.inactive {
        height: 0;
        line-height: 0;
        opacity: 0;
        visibility: collapse;
      }
    }
  }
}

.slideContainer {
  white-space: nowrap;
  animation-name: marquee;
  animation-timing-function: linear;
  animation-duration: 10s;
  animation-iteration-count: infinite;
}

@keyframes marquee {
  from {
    transform: translate(0%);
  }
  99%,
  to {
    transform: translate(-100%);
  }
}

.checkbox {
  width: 1vw;
}

.selectTracerContainer {
  // border:1px solid yellow;
  position: relative;
  height: 1.5vw;
  width: 100%;
  background: #292d30;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;

  .selectTracer {
    // background-color: red;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // gap: 75%;
    > div {
      width: 95%;
    }
    > img {
      width: 0.7vw;
    }
  }

  .selectTracer_divs {
    // background-color: blue;
    // border:1px solid red;
    width: 100%;
    position: absolute;
    top: 100%;
    z-index: 3;
  }

  .selectTracer_div {
    // border:1px solid red;
    width: 100%;
    height: 1.5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #292d30;
    // font-size: 0.6vw;
    // display: none;
    &:hover {
      background-color: $dark-hover;
    }
  }
}

.flipContainer {
  width: 1vw;
  margin: 0 auto;
}
