@import '3_styles/_variables';
@import '3_styles/_mixins';

.coronal {
  width: 100%;
  height: 100%;
}

.sagittal {
  width: 102%;
  height: 102%;
}

.axial {
  width: 100%;
  height: 100%;
}

.mip {
  width: 140%;
  height: 100%;
}

.viewerBody {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: stretch;
  transform: translate(-50%, -50%);
  position: relative;
  top: 50%;
  left: 50%;
}
